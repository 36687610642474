<template>
  <div class="credits">
    <div class="credits__intro">{{ label }}</div>
    <img :src="`${$baseUrl}images/LogoTerna.svg`" alt="logo di Terna" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.credits {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include button-m-bold-serif;

  &__intro {
    width: max-content;
    margin-right: 2rem;
    filter: drop-shadow($white-shadow);
  }
}
</style>
