import { mapActions } from "vuex";
export const indexHelper = {
  data() {
    return {
      mappingClasses: {
        5: "sky",
        6: "earth",
        7: "sea"
      },
      mappingIndex: {
        sky: 5,
        earth: 6,
        sea: 7
      }
    };
  },

  methods: {
    dynamicClass(i) {
      const mappingClasses = {
        5: "sky",
        6: "earth",
        7: "sea"
      };

      return mappingClasses[i];
    },

    changeIndex(name, value) {
      if (name !== null) {
        const mappingIndex = {
          sky: 5,
          earth: 6,
          sea: 7
        };

        this.setIndexFromMenu(mappingIndex[name]);
      } else {
        this.setIndexFromMenu(value);
      }

    },
    ...mapActions(["setIndexFromMenu"])
  }
};
