import { render, staticRenderFns } from "./SplashContent.vue?vue&type=template&id=120f1dbe&scoped=true&"
import script from "./SplashContent.vue?vue&type=script&lang=js&"
export * from "./SplashContent.vue?vue&type=script&lang=js&"
import style0 from "./SplashContent.vue?vue&type=style&index=0&id=120f1dbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120f1dbe",
  null
  
)

export default component.exports