export default {
  computed: {
    timing() {
      let scenesList = [];
      let transitionsList = [];
      if (this.data) {
        if (
          this.data.markers.length &&
          this.data.markers.find((o) => o.cm.includes("scen"))
        ) {
          let scenes = this.data.markers.filter((o) => o.cm.includes("scen"));
          let transitions = this.data.markers.filter((o) =>
            o.cm.includes("transizion")
          );
          scenes.forEach((e, i) => {
            if (transitions[i]) {
              scenesList.push([e.tm, transitions[i].tm]);
            }
          });
          transitions.forEach((e, i) => {
            if (scenes[i + 1]) {
              transitionsList.push([e.tm, scenes[i + 1].tm]);
            }
          });
        } else {
          let sceneLayer = (
            this.data.layers.find((o) => o.nm.toUpperCase() == "SLIDE") ||
            this.data.layers[0]
          ).t.d.k[0].s.t;
          let transitionLayer = (
            this.data.layers.find((o) => o.nm.toUpperCase() == "TRANSIZIONE") ||
            this.data.layers[1]
          ).t.d.k[0].s.t;

          scenesList = JSON.parse(sceneLayer);
          transitionsList = JSON.parse(transitionLayer);
        }
        return {
          segments: scenesList,
          transitions: transitionsList
        };
      } else {
        return null;
      }
    },
    transitions() {
      return this.timing ? this.timing.transitions : null;
    },
    segments() {
      return this.timing ? this.timing.segments : null;
    },
  }
}