<template>
  <div class="CTMCircles">
    <h1 class="CTMCircles__cta">{{ cta }}</h1>
    <div class="CTMCircles__ring">
      <CircleBTN></CircleBTN>
    </div>
    <LandingNotes v-if="$tvaMq === 'desktop' || $tvaMq === 'large'"/>
  </div>
</template>

<script>
import CircleBTN from "@/components/UI/landing/CircleBTN.vue";
import LandingNotes from "@/components/UI/landing/LandingNotes.vue";
import { indexHelper } from "@/mixins/ui-indexHelper";
export default {
  mixins: [indexHelper],
  components: {
    CircleBTN,
    LandingNotes
  },
  computed: {
    isTabletOrMobile() {
      return this.$tvaMq === "tablet" || this.$tvaMq === "mobile";
    },
    content() {
      return this.$t("menu");
    },
    cta() {
      return this.$t("landing").ctaCircles;
    }
  }
};
</script>

<style lang="scss" scoped>
.CTMCircles {
  height: calc(100% - 6.5rem);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__cta {
    @include button-m-bold;

    width: 100%;
    color: $white;
    text-shadow: $white-shadow;
    text-transform: uppercase;

    .mobile & {
      font-style: italic;
      max-width: 223px;

      @include button-s;
    }
  }

  &__ring {
    display: flex;

    .large &,
    .desktop & {
      height: 100%;
      width: 120vw;
      padding: 0 100px; // this changes big circle curve at the edges
      border-radius: 50% 50%;
      transform: translateY(30vh);
      transform: translateY(calc(var(--vh, 1vh) * 30));
      border-top: 1px solid $white;
      @include effect-style-bright;

      flex-direction: row;
      justify-content: space-evenly; // space circles on the x
      align-items: flex-start;
    }

    .tablet &,
    .mobile & {
      height: 100%;
      width: 100%;

      flex-direction: column;
      padding-bottom: 0.5rem;
    }
  }
}
</style>
