<template>
  <div class="section" :key="index">
    <div class="section__title">
      <h1
        class="section__title--line"
        v-for="(t, i) in isMobileOrTablet
          ? content.title_mobile
          : content.title"
        :key="i"
      >
        {{ t.toUpperCase() }}
      </h1>
    </div>
    <div class="section__box">
      <div class="section__box--fadingLine"></div>
      <div class="section__box--text" v-html="content.text" />
      <div class="section__box--fadingLine"></div>
    </div>

    <LandingScrollButton
      class="section__scroll-btn"
      :label="content.cta"
      :nextIndex="index + 1"
    />
  </div>
</template>

<script>
import LandingScrollButton from "@/components/UI/landing/LandingScrollButton.vue";

export default {
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    isMobileOrTablet() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    },
    content() {
      return this.$t("landing").sections[this.index - 1];
    }
  },
  components: {
    LandingScrollButton
  }
};
</script>
<style lang="scss" scoped>
.section {
  $line-width: 110px;

  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  margin-top: 8%;
  color: $white;
  position: relative;
  z-index: 1;
  max-width: 1500px;

  .tablet &,
  .mobile & {
    margin-top: 0;
    justify-content: flex-start;
    max-width: 90%;
  }

  &__title {
    @include title-xl;
    align-self: center;
    text-align: left;
    width: 100%;

    .mobile & {
      @include p-m;
    }

    &--line {
      margin: 15px 0;
      width: fit-content;
      position: relative;

      .mobile & {
        margin: 0.5rem 0;
      }

      &:before {
        content: "";
        position: absolute;

        background: $white;

        opacity: 0.2;
        border-radius: 15px;
        transform: translateY(50%);

        width: calc(100% + 30px);
        left: -15px; // half of width increase
        height: 40px;

        .mobile & {
          width: calc(100% + 15px);
          left: -7.5px; // half of width increase
          height: 23px;
          top: 2px;
        }
      }
    }
  }

  &__box {
    @include p-l;

    align-self: flex-end;
    text-align: left;
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    .mobile & {
      @include p-s;

      line-height: 1.8rem;
    }

    &--text {
      .desktop &,
      .large & {
        // in the mock up they all have different positions and different width...adjust by index if necessary
        max-width: 750px;
        flex-grow: 1;
        padding: 20px;
        @include effect-style-bright-side-only;
      }
    }

    &--fadingLine {
      width: $line-width;
      height: 1px;

      align-self: center;
      .tablet &,
      .mobile & {
        display: none;
      }

      @include effect-style-bright;
      @include fadingLines(0.5, -1);

      &:nth-of-type(1) {
        @include fadingLines(0.5, 1);
      }
    }
  }

  &__scroll-btn {
    // top: calc(100% - 8rem);
    top: 80%;

    left: calc(100% - #{$line-width});
    transform: translate(-50%, -100%);

    .tablet &,
    .mobile & {
      left: calc(100% - 1rem);
    }

    .mobile & {
      top: calc(100% - 7rem);
    }
  }
}
</style>
