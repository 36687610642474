<template>
  <div class="landing-scroll-button" @click="scrollDown">
    <button
      class="landing-scroll-button__scroll-btn"
      :aria-label="$t('landing.scrollCta')"
    >
      <p v-if="withText">{{ $t("landing.scrollCta") }}</p>
      <svgicon
        :class="['landing-scroll-button__scroll-icon', { animate: !withText }]"
        height="4.38rem"
        name="arrow-down"
        color="var(--white) r-var(--white)"
      />
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    nextIndex: Number,
    withText: Boolean
  },
  methods: {
    scrollDown() {
      this.setIndexFromMenu(this.nextIndex);
    },
    ...mapActions(["setIndexFromMenu"])
  }
};
</script>

<style lang="scss" scoped>
@keyframes button-moves {
  to {
    transform: translateY(1rem);
  }
}

.landing-scroll-button {
  position: absolute;

  top: calc(100vh - 1.5rem);
  top: calc(var(--vh, 1vh) * 100 - 1.5rem);
  transform: translateY(-100%);
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__scroll-btn {
    @include button-m-bold;
    text-shadow: $white-shadow;
    color: $white;
  }

  &__scroll-icon {
    transform: translateY(0.5rem);
    transition: transform 0.3s ease;

    &.animate {
      animation: button-moves 0.8s alternate infinite;
    }
  }

  &:hover {
    cursor: pointer;
    filter: drop-shadow(#{$white-shadow});

    .landing-scroll-button__scroll-icon {
      &.animate {
        animation-play-state: paused;
      }

      &:not(.animate) {
        transform: translateY(1rem);
      }
    }
  }

  .mobile & {
    top: calc(100vh);
    top: calc(var(--vh, 1vh) * 100);
  }
}
</style>
