<template>
  <div class="splash-content">
    <h2 class="splash-content__sub-title">{{ content.comingSoon }}</h2>
    <h1 class="splash-content__title">
      <span
        v-for="element in content.ctm"
        :key="element"
        class="splash-content__title__element"
        >{{ element }}</span
      >
    </h1>
    <div class="splash-content__bottom">
      <p class="splash-content__bottom__info">{{ content.journey }}</p>
      <img
        class="splash-content__bottom__logo"
        :src="`${$baseUrl}images/LogoTerna.svg`"
        alt="logo di Terna"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$t("splash");
    }
  }
};
</script>

<style lang="scss" scoped>
.splash-content {
  position: absolute;
  color: $white;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;

  &__sub-title {
    @include button-m-bold-serif;

    margin-bottom: 0.5rem;
  }

  &__title {
    @include title-l-serif;

    letter-spacing: 0.1em;
    margin-bottom: 1.5rem;

    &__element:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__bottom {
    @include button-m-bold-serif;

    display: flex;
    align-items: center;
    justify-content: center;

    :first-child {
      margin-right: 2.5rem;
    }
  }

  .mobile & {
    .splash-content {
      &__title {
        font-size: 1.5rem;
      }

      &__sub-title,
      &__bottom {
        font-size: 0.9rem;
      }

      &__bottom__logo {
        width: 6.5rem;
      }
    }
  }
}
</style>
