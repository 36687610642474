<template>
  <div class="landingContent" :style="{ '--bullet-scale': bulletScale }">
    <!-- BACKROUND VIDEO -->
    <video
      class="landingContent__bgvideo"
      v-if="landingIndex <= 4"
      autoplay
      muted
      loop
      playsinline
      id="myVideo"
    >
      <source
        :src="`${$baseUrl}videos/landing-bg-video.mp4`"
        type="video/mp4"
      />
    </video>

    <transition mode="out-in" name="fade">
      <BodymovinSlider
        v-if="segments && transitions && landingIndex < 4"
        :index="landingIndex"
        :path="path"
        :segments="segments"
        :transitions="transitions"
        :speed="0.5"
        :position="bodyMovingStyle"
        v-on:status="status = $event"
      />
    </transition>

    <transition :name="transition">
      <!-- CIELO TERRA MARE -->
      <MainCover v-if="landingIndex === 0 && !isSplash" />

      <!-- Remove when splash not necessary anymore -->
      <SplashContent v-if="isSplash" />

      <!-- SCROLLING SECTION -->
      <div
        class="landingContent__sections"
        v-if="landingIndex > 0 && landingIndex < 4"
        :key="landingIndex"
      >
        <ContentSection :index="landingIndex" />
      </div>
      <!-- PALLOZZI -->
      <div class="landingContent__circles" v-if="landingIndex === 4">
        <CTMCircles />
      </div>
    </transition>
  </div>
</template>
<script>
import ContentSection from "@/components/UI/landing/ContentSection.vue";
import MainCover from "@/components/UI/landing/MainCover.vue";
import CTMCircles from "@/components/UI/landing/CTMCircles.vue";
import BodymovinSlider from "@/components/UI/landing/BodymovinSlider.vue";
import timings from "@/mixins/timings";

import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import SplashContent from "../../SplashContent.vue";

export default {
  components: {
    ContentSection,
    MainCover,
    CTMCircles,
    BodymovinSlider,
    SplashContent
  },
  mixins: [timings],
  props: {
    transition: String,
    isSplash: Boolean
  },
  data() {
    return {
      progress: 0,
      data: null,
      path: "",
      status: "",
      bodyMovingStyle: {
        width: "100%",
        height: "100%",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      },
      bulletScale: 1,
    };
  },
  computed: {
    content() {
      return this.$t("landing").cover;
    },
    isDev() {
      return process.env.NODE_ENV === "development";
    },
    smallscreen() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    },
    ...mapGetters(["landingIndex", "getVideoData"])
  },
  created() {
    this.setMobileBodymovingAspect(this.smallscreen);

    if (this.getVideoData) {
      this.data = this.getVideoData;
    } else {
      this.path = `${this.$baseUrl}videos/waves.json`;
      axios
        .get(this.path)
        .then(response => {
          // handle success
          this.data = response.data;
        })
        .catch(error => {
          // handle error
          console.log(error);
        });
    }
  },
  watch: {
    smallscreen(value) {
      this.setMobileBodymovingAspect(value);
    }
  },
  methods: {
    scrollDown() {
      this.setIndexFromMenu(1);
    },
    setMobileBodymovingAspect(smallscreen) {
      // Default aspect ratio of bodymovin video is more or less 1.8
      this.bulletScale = 1.8 + (1.8 - window.innerWidth / window.innerHeight);

      if (smallscreen) {
        this.bodyMovingStyle.width = "125%";
      } else {
        this.bodyMovingStyle.width = "100%";
      }
    },
    ...mapActions(["setIndexFromMenu"])
  }
};
</script>
<style lang="scss" scoped>
.landingContent {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: relative;
  padding: 0 $landing-content-padding;

  .tablet &,
  .mobile & {
    &__sections {
      margin-top: 100px; // header mobile
    }
  }

  &__sections {
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__circles {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-top: 3.75rem;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;

    .mobile &,
    .tablet & {
      padding-top: 0;
    }
  }

  &__bgvideo {
    @include video-styling;
  }

  // To make animation on mobile look good, we both want to stretch SVG (not preserve aspect ratio)
  // and make bullets look round. So we scale the x axis to make them look round.

  .mobile &,
  .tablet & {
    ::v-deep
      g[clip-path^="url(#__lottie_element_"]
      g
      g
      path:not([stroke-linecap="butt"]) {
      transform: scale(var(--bullet-scale), 1);
    }
  }
}
</style>
