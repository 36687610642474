<template>
  <div :class="[$tvaMq, 'mainCover']">
    <div class="mainCover__hero">
      <div
        v-for="(label, i) in content.labels"
        :key="i"
        class="mainCover__hero--row"
      >
        <Credits
          :label="content.iconLabel"
          class="credits"
          v-if="(i === 1) & !isMobileOrTablet"
        ></Credits>

        <h1 class="text">
          {{ label.toUpperCase() }}
          <sub class="subtext" v-if="content.sub">{{ content.sub[i] }}</sub>
        </h1>
      </div>
      <div class="mainCover__hero--row">
        <Credits
          :label="content.iconLabel"
          class="credits"
          v-if="isMobileOrTablet"
        ></Credits>
      </div>
    </div>

    <LandingScrollButton :nextIndex="1" withText />

  </div>
</template>
<script>
import Credits from "@/components/UI/landing/Credits.vue";
import LandingScrollButton from "@/components/UI/landing/LandingScrollButton.vue";

import { mapGetters } from "vuex";

export default {
  computed: {
    content() {
      return this.$t("landing").cover;
    },
    isMobileOrTablet() {
      return this.$tvaMq === "mobile" || this.$tvaMq === "tablet";
    },
    ...mapGetters(["landingIndex"])
  },
  components: {
    Credits,
    LandingScrollButton
  }
};
</script>

<style lang="scss" scoped>
.mainCover {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(100% - #{$landing-content-padding} * 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;

  .desktop &,
  .large & {
    padding-bottom: 4rem;
  }

  .mobile &,
  .tablet & {
    justify-content: flex-start;
  }

  &__hero {
    height: 100%;
    width: 100%;
    max-width: 900px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;
    text-shadow: 0px 0px 17.2015px $white_70;

    .mobile &,
    .tablet & {
      max-width: unset;
      // border: 2px solid yellow;
      height: calc(80% - 3rem);
      justify-content: space-evenly;
      margin-top: 3rem;
    }

    &--row {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;

      text-transform: uppercase;

      .text {
        position: relative;
      }

      .subtext {
        display: inline-block;
        position: absolute;
        font-size: 1.5rem;
        line-height: 1.5rem;
        top: 75%;
        transform: translateX(1rem);
      }

      .mobile &,
      .tablet & {
        @include section-s;
        max-width: unset;
        justify-content: center;
        flex-grow: 1;
        flex-wrap: wrap;

        .text {
          // min-width: 100%;
          .subtext {
            left: 100%;
            transform: translate(-75%, 1rem);
          }
        }
      }

      .desktop &,
      .large & {
        @include section-m;
        &:nth-of-type(2) {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .text {
            flex-grow: 1;
            max-width: 900px;
            position: relative;
          }

          .credits {
            transform: translate(-8vw, 20%);
          }
        }

        &:nth-of-type(3) {
          justify-content: center;
        }
      }
    }
  }
}
</style>
