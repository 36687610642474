<template>
  <div :style="position" ref="video"></div>
</template>

<script>
import lottie from "lottie-web";
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    index: Number,
    path: String,
    segments: Array,
    transitions: Array,
    position: Object,
    frameRate: {
      type: Number,
      default: 1
    },
    speed: {
      type: Number,
      default: 1
    },
    transitionsSpeed: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      direction: "",
      status: "",
      totalFrames: 0,
      playingIndex: 0,
      animationSettings: {
        renderer: "svg",
        loop: true,
        autoplay: false,
        rendererSettings: { preserveAspectRatio: "none" }
      }
    };
  },
  computed: {
    _segments() {
      return _.map(this.segments, (segment) => {
        return _.map(segment, (value, index) => {
          if (index === 0) {
            return value * this.frameRate + 1;
          } else {
            return value * this.frameRate;
          }
        });
      });
    },
    _transitions() {
      return _.map(this.transitions, (transition) => {
        return _.map(transition, (value) => value * this.frameRate);
      });
    },
    ...mapGetters(["getVideoData"])
  },
  mounted() {
    this.$nextTick(() => {
      this.setLottie();
      this.animation.addEventListener("DOMLoaded", () => {
        this.animation.playSegments(this._segments[this.index], true);
      });
      this.animation.addEventListener("segmentStart", (e) => {
        this.totalFrames = e.totalFrames;
        // set status
        this.status = _.some(this._segments, (s) => {
          return (
            _.difference(s, [e.firstFrame, e.firstFrame + e.totalFrames])
              .length === 0
          );
        })
          ? "segment"
          : "transition";
        // set playingIndex
        if (this.playingIndex !== this.index) {
          this.playingIndex = this.index;
        }
      });
      this.animation.addEventListener("enterFrame", (e) => {
        let progress = e.currentTime / this.totalFrames;
        this.$emit("progress", progress);
      });
    });
  },
  watch: {
    direction(direction) {
      this.$emit("direction", direction);
    },
    status(status) {
      this.$emit("status", status);
      if (status === "transition") {
        this.animation.setSpeed(this.speed);
      }
    },
    index(index) {
      if (this.playingIndex !== index) {
        this.animation.setSpeed(this.transitionsSpeed);
        this.status = "segment-fast";
      }

      if (
        this.playingIndex - index <= 1 &&
        this.playingIndex - index >= -1 &&
        this.playingIndex !== index &&
        this.status !== "transition"
      ) {
        if (index > this.playingIndex) {
          this.direction = "foreward";
          this.animation.playSegments(
            [this._transitions[index - 1], this._segments[index]],
            false
          );
        } else {
          this.direction = "backward";
          this.animation.playSegments(
            [
              [this._transitions[index][1], this._transitions[index][0]],
              this._segments[index]
            ],
            false
          );
        }
      } else {
        this.animation.stop();
        this.animation.setSpeed(this.transitionsSpeed);
        this.animation.playSegments(this._segments[index], true);
      }
    }
  },
  methods: {
    setLottie() {
      if (this.getVideoData) {
        this.animation = lottie.loadAnimation({
          container: this.$refs.video,
          ...this.animationSettings,
          animationData: this.getVideoData
        });
      } else {
        this.animation = lottie.loadAnimation({
          container: this.$refs.video,
          ...this.animationSettings,
          path: this.path
        });
      }
      this.animation.setSpeed(this.speed);
    }
  }
};
</script>
