<template>
  <footer class="LandingNotes">
    <div class="LandingNotes__string" v-html="content.string + '&nbsp;&nbsp;|&nbsp;&nbsp;'+content.notes"></div>
    <!-- <div class="LandingNotes__notes" v-html="content.notes"></div> -->
  </footer>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$t("landing").notes;
    }
  }
};
</script>

<style lang="scss" scoped>
.LandingNotes {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 0.4rem;
  @include details;
  color: #fff;

  text-align: left;
  font-size: 10.5px;
  line-height: 1.2;

  display: flex;
  flex-direction: row;
}
</style>

<style lang="scss">
.LandingNotes {
    &__notes {
    text-decoration: underline;
  }
  
}
</style>