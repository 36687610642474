<template>
  <div class="btnsContainer">
    <button
      v-for="(c, index) in content"
      :key="index"
      :class="[dynamicClass(index + 5), 'btnsContainer__circle']"
      @click="changeIndex(c.name)"
    >
      <div
        class="btnsContainer__circle--inner"
        :style="{
          backgroundImage: `url(${$baseUrl}images/covers/${c.name}/icon.jpg)`
        }"
      >
        <img class="plus-icon" :src="`${$baseUrl}images/PlusIcon.svg`" alt="" />
      </div>
      <div class="btnsContainer__circle--text">
        {{ c.label.toUpperCase() }}
      </div>
    </button>
  </div>
</template>

<script>
import { indexHelper } from "@/mixins/ui-indexHelper";

export default {
  mixins: [indexHelper],
  computed: {
    content() {
      return this.$t("menu");
    },
    cta() {
      return this.$t("landing").ctaCircles;
    }
  }
};
</script>

<style lang="scss" scoped>
$circleSize: $circle-btn-size; // dotted circle size

.btnsContainer {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly; // space circles on the y
  align-items: flex-start;

  .tablet &,
  .mobile & {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  &__circle {
    width: $circleSize;
    height: $circleSize;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    backdrop-filter: blur(2px);
    background-image: radial-gradient(
      rgba(1, 1, 1, 0) 69%,
      $white_25 69%,
      rgba(1, 1, 1, 0) 72%
    );

    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: 98%;
      height: 98%;
      left: 1%;
      top: 1%;

      border-radius: 50%;
      background: repeating-conic-gradient(
        from 0deg,
        $white 0deg 3deg,
        rgba(1, 1, 1, 0) 3deg 6deg
      );
      -webkit-mask: radial-gradient(rgba(1, 1, 1, 0) 69.5%, $black 71%);
      mask: radial-gradient(rgba(1, 1, 1, 0) 69.5%, $black 71%);
    }

    .large &,
    .desktop & {
      &.sky,
      &.sea {
        transform: translateY(calc($circleSize/3 * -1));
      }
      &.earth {
        transform: translateY(calc($circleSize/2 * -1));
      }
    }

    //[TODO] to be replaced with "patchwork"
    &--inner {
      position: absolute;
      align-self: center;
      width: 80%;
      height: 80%;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      // [TODO] this is to avoid white border to be visible --> ask frenky to png or use mask maybe?
      // background-size: cover;
      background-size: 105% 105%;
      background-position: center;
      transition: background-size 0.8s ease;

      .plus-icon {
        height: calc($circleSize/4);
      }
    }

    &--text {
      transform: translateY(calc($circleSize/2 + $circle-btn-text-translate));

      color: $white;
      align-self: center;
      pointer-events: none;
      transition: $white-shadow-transition;

      @include p-m;

      .tablet &,
      .mobile & {
        font-size: 1rem;
        padding-bottom: 0.75rem;
      }
    }
  }
  button:hover {
    .btnsContainer__circle--inner {
      background-size: 130% 130%;
    }
    .btnsContainer__circle--text {
      text-shadow: $white-shadow;
    }
  }
}
</style>
